.main-app-container[data-name="voucer"] {
    .voucer-header {
        display: flex;
        align-items: center;
        gap: 13px;
        padding-bottom: 15px;

        .search-field {
            width: 190px;
            max-width: 100%;
        }

        .right {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .action-cell {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.add-voucer-dialog {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .form-control {
        .label {
            font-size: 12px;
            font-weight: 500;
        }

        .flex {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}
