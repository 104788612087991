.main-app-container[data-name="chat"] {
    .empty-conversation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .chat-container {
        display: flex;
        gap: 25px;

        .chat-data {
            background: #F3F3F3;
            .chat-footer {
                display: flex;
                align-items: center;
                gap: 10px;

                .send-btn {
                    display: flex;
                    align-items: center;

                    .icon {
                        font-size: 16px;
                    }
                }
            }
        }

        .chat-main-container {
            width: 100%;
            position: relative;
        }

        .conversation-container {
            
            width: 260px;
        }

        .head {
            display: flex;

            .add-chat {
                margin-left: 15px;
                
                .icon {
                    font-size: 16px;
                }
            }
        }

        .conversations {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;
            // background: red;
            max-height: calc(100vh - 250px);
            overflow-y: auto;
            padding: 5px;

            .conversation-item {

                &[data-active="1"] {
                    border-color: var(--primary-color);
                }

                .flex {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .info {
                        max-width: calc(100% - 120px);
                        width: 100%;

                        .name, .last-message {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .right {
                        display: felx;
                        flex-direction: column;
                        width: fit-content;
                        margin-left: auto;
                        margin-right: 0;
                        
                        .date {
                            white-space: nowrap;
                            font-size: 11px;
                            color: dimgray;
                        }

                        .dot {
                            width: 8px;
                            height: 8px;
                            background: var(--primary-color);
                            border-radius: 50%;
                            display: flex;
                            margin-left: auto;
                            margin-right: 0;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }

    .chat-data {
        position: relative;
        overflow: hidden;
        
        .scroll-down-btn {
            position: absolute;
            bottom: 100px;
            right: 50px;
            z-index: 100;
            border: 1px solid #fff !important;
        }

        .chat-items {
            height: calc(100vh - 350px);
            max-height: calc(100vh - 350px);
            min-height: calc(100vh - 350px);
            overflow-y: auto;
            margin-block: -20px;
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            position: relative;


            .chat-item {
                &:not(:first-child) {
                    margin-top: 15px;
                }
                
                .information {
                    background: #1E2C3A;
                    color: #fff;
                    padding: 2px 15px;
                    border-radius: 10px;
                    display: flex;
                    width: fit-content;
                    margin-inline: auto;
                    max-width: calc(100% - 100px);
                }

                .isnotme .rce-mbox {
                    background: #fff;
                    color: #000;

                    .rce-mbox-left-notch {
                        fill: #fff;
                    }

                    .rce-mbox-time {
                        color: #00000093;
                    }
                }

                .image-message {
                    width: 200px;
                }

                .image-message.isme {
                    display: flex;
                    margin-left: auto;
                    margin-right: 0;
                    width: 200px;
                    border-radius: 10px;
                    border: 1px solid var(--primary-color);
                }
                
                .isme .rce-mbox {
                    background: #D8F7EF;
                    color: #000;

                    .rce-mbox-right-notch {
                        fill: #D8F7EF;
                    }

                    .rce-mbox-time {
                        color: #00000093;
                    }
                }
            }
        }

        .chat-header {
            display: flex;
            width: 100%;
            align-items: center;

            .action-btn {
                display: flex;
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
            }

            .profile {
                display: flex;
                align-items: center;
                width: fit-content;
                gap: 10px;
                height: 41.13px;

                .info {
                    .name {
                        font-weight: 600;
                        font-size: 16px;
                    }

                    .email {
                        font-style: italic;
                        font-size: 12px;
                        line-height: 16px;
                        color: dimgray;
                    }
                }

                .icon {
                    font-size: 30px;
                }
            }
        }
    }
}