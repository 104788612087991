.element.chat-float-btn {
    right: 30px;

    .icon {
        font-size: 21px;
        margin-left: -2px;
        margin-top: 5px;
    }

    .badge {
        background :red;
    }
}

    