.main-app-container[data-name="private-profit-barang"] {
    .range-pickerofdate {
        margin-top: 20px;
    }

    .data-footer {
        display: flex;
        margin-top: 15px;
        gap: 20px;

        .left {
            display: flex;
            width: fit-content;
            align-items: center;
            gap: 10px;
        }
        
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            align-items: end;

            .form-control {
                .title {
                    font-weight: 500;
                }
            }
        }
    }

    .data-preview {
        max-width: calc(100vw - 350px);

        .ant-table-cell {
            white-space: nowrap;
        }

        .expanded-element {
            .product {
                .name {
                    font-size: 17px;
                    font-weight: 500;
                }

                .row {
                    display: flex;
                }

                .images {
                    display: flex;
                    gap: 6px;
                    width: fit-content;

                    img {
                        width: 96px;
                        border-radius: 8px;
                    }
                }

                .unit-price {
                    color: var(--primary-color);
                    font-weight: 600;
                    margin-top: 8px;
                }
                
                .prod-url {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .props {
                    margin-top: 6px;
                }
            }
        }

        .ant-table-summary {
            .ant-table-cell {
                padding-inline: 20px;
            }
        }

        .finished-row {
            background: #eb1c232e !important;

            .ant-table-cell {
                background: #eb1c232e;
            }
        }
        
        tr.ant-table-row {
            .ant-table-cell {
                padding-inline: 20px;
            }
        }

        .ant-table-thead {
            .ant-table-cell {
                text-align: center;
            }
        }

        .search-field {
            width: 300px;
            max-width: 100%;
        }

        .no {
            text-align: center;
        }

        .actions {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        

        .ant-table-container {
            border-color: rgba(57, 57, 57, 0.353) !important;
        }

        table {
            * {
                border-color: rgba(57, 57, 57, 0.353) !important;
            }

            .no {
                font-size: 14px;
                font-weight: 700;
                width: 40px;
            }

            .admin {
                font-size: 13px;
            }
            
            .invoice {
                font-style: italic;
                font-size: 13px;
                font-weight: 500;

                &:hover {
                    text-decoration: underline;
                }
            }

            .admin-note {
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .processing-date {
                .date-format {
                    font-size: 14px;
                    font-weight: 500;
                }

                .fromnow {
                    font-size: 11px;
                }

                .date-format, .fromnow {
                    white-space: nowrap;
                }
            }
        }
    }
}