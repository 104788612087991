.child-container[data-name="aduan"] {
    .top {
        background: var(--primary-color);
        padding-block: 60px;
        padding-inline: 10px;

        .title,.subtitle {
            color: #fff;
        }

        .title {
            font-weight: 500;
            font-size: 40px;
            line-height: 53px;
            text-align: center;
        }

        .subtitle {
            text-align: center;
            font-size:18px;
            line-height: 26px;
            margin-top: 25px;
        }
    }

    .form-card {
        margin-top: 50px;
        width: 500px;
        display: flex;
        margin-inline: auto;
        flex-direction: column;

        .send-btn {
            display: flex;
            width: fit-content;
        }

        .title-card {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }

        .forms {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .form-control {
                .title {
                    font-weight: 500;
                    font-size: 13px;
                }

                .error-msg {
                    font-weight: 400;
                    font-size: 11px;
                    color: var(--primary-color);
                }
            }
        }
    }
}