.child-container[data-name="not-found"]{
    .head-404 {
        background: var(--primary-color);
        color: #fff;
        padding-block: 100px;

        .title {
            text-align: center;
            font-size: 40px;
            line-height: 48px;
            font-weight: 700;
            color: #fff;
        }
        
        .description {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-top: 20px;
            color: #fff;
        }

        .back-to-homepage {
            display: flex;
            width:fit-content;
            margin: auto;
            margin-top: 20px;
            color: var(--primary-color) !important;
        }
    }
}