.main-app-container {
    display: flex;
    width: 95%;
    margin-inline: auto;
    gap: 30px;

    .right-side {
        min-height: calc(100vh - 150px);
        width: 100%;
        margin-block: 20px;
        position: relative;

        .main-app-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .sidebar {
        width: 300px;
        margin-block: 20px;
        
        .ant-divider  {
            margin-block: 10px;
        }
        
        .profile {
            display: flex;
            align-items: center;
            gap: 10px;

            .right {
                .name {
                    font-size: 17px;
                    font-weight: 500;
                }

                .email {
                    font-style: italic;
                    font-size: 13px;
                }
            }
        }

        .menus {
            display: flex;
            flex-direction: column;
            width: 100%;

            .ant-menu-item-icon {
                font-size: 20px;
            }

            .ant-menu-item {
                display: flex;
                align-items: center;
                
                .icon {
                    font-size: 20px;
                }
            }

            .ant-menu-title-content {
                font-weight: 600;
            }

            .menu-item {
                width: 100%;
                text-align: start;
                display: flex;
                align-items: center;

                &[data-active="true"] {
                    span {
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                }

                .icon {
                    color: var(--primary-color);
                }

                span {
                    margin-top: 2px;
                }
            }
        }
    }
}