.main-app-container[data-name="new-order"] {
    .divider {
        margin-block: 10px;
    }

    .footer-actions {
        display: flex;
        gap: 30px;
        margin-top: 15px;

        .start-side {
            display: flex;
            width: 100%;

            .form-control.note {
                display: flex;
                flex-direction: column;
                width: 100%;

                .title {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .end-side {

            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;

            .make-order-btn {
                display: flex;
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
            }

            .error-msg {
                font-size: 12px;
                color: var(--primary-color);
                text-align: end;
            }

            .select-product-image {
                margin-bottom: 15px;
            }

            .form-control.select-address-container {
                margin-bottom: 15px;
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
                width: 100%;

                .repacking-check {
                    display: flex;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: 0;
                }

                .title {
                    font-size: 12px;
                    font-weight: 600;
                }

                .select-address {
                    display: flex;
                    flex: 1;
                }
            }
        }
    }

    .order-custom-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        
        .row {
            display: flex;
            gap: 15px;
            flex: 1;

            .form-control {
                width: 100%;
                flex:1;
            }

            .fit {
                width: fit-content !important;
                flex: unset;
            }
        }

        .forms {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .form-control {
                display: flex;
                flex-direction: column;

                .label {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 600;
                }
            }
        }

        .property {
            display: flex;
            flex-direction: column;

            .properties {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .property-item {
                    position: relative;

                    .property-item-container {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        gap: 20px;
                        position: relative;
                    }
                }
            }

            .add-variant-btn {
                margin-top: 20px;
            }
        }
    }
    

    .order-auto-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        
        .form-control {
            display: flex;
            flex-direction: column;
            gap: 10px;
            
            .label {
                font-size: 12px;
                font-weight: 600;
            }
            
            .form-field {
                display: flex;
                width: 100%;
            }

            .error-field {
                font-size: 11px;
                color: var(--primary-color);
                margin-top: -10px;
            }
        }
    }

    .order-auto-product-detail {
        margin-top: 20px;

        .product-detail {
            display: flex;
            gap: 20px;

            .left {
                display: flex;
                flex-direction: column;
                
                .images-fluid {
                    max-width: 250px;
                    width: fit-content;

                    .product-image {
                        width: 96px !important;
                        height: 96px !important;
                        border-radius: 9px;
                    }
                }
            }
            
            .right {
                display: flex;
                flex-direction: column;

                .product-name {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .form-control {
                    display: flex;
                    flex-direction: column;

                    .label {
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                    }
                }

                .form-control.props {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;

                    .control-item {
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
    }
}