.main-app-container[data-name="address"] {
    .address-header {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: row;
        height: 30px;

        .title {
            display: flex;
            width: 100%;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #202223;
        }


        .right-side {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            margin-top: -10px;

            .add-address-btn {
                font-weight: 500;
                font-size: 14p;
                line-height: 28px;
                display: flex;
                align-items: center;
            }
        }
    }

    .divider {
        margin-block: 13px;
    }

    .empty-container {
        margin-top: 80px;
    }

    .addresses {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .address-item {

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color:#202223;
            }
            
            .actions {
                display: flex;
                width: fit-content;
                align-items: center;
                gap: 10px;

                .edit {
                    color: dimgray;
                }
            }
        }
    }
}

.add-address-modal {
    .ant-modal-footer {
        margin-top: 30px;
    }

    .forms {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .form-control {
            display: flex;
            flex-direction: column;
            gap: 7px;
            
            .label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                color: #202223;
            }

            .input {
                width: 100%;
            }
        }
    }
}

// Edit address dialog
.address-edit-dialog {
    .forms {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .form-control {
            width: 100%;

            .label {
                font-size: 13px;
                font-weight: 600;
                line-height: 24px;
            }

            .field {
                width: 100%;
            }
        }
    }
}