.main-app-container[data-name="order"] {
    
    .status-tab {
        max-width: calc(100vw - 400px);
    }

    .order-header {
        display: flex;
        align-items: center;
        gap: 10px;

        .search {
            .search-icon {
                color: #2022237a;
            }
        }

        .finish-status-select {
            width: 180px;
        }
    }

    .load-more-btn {
        display: flex;
        width: fit-content;
        margin-inline: auto;
        margin-top: 20px;
    }

    .orders {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .order-item {

            .order-detailed-row {
                display: flex;
                align-items: center;

                .admin-work-status-card {
                    width: fit-content;
                    margin-block: 15px;
                }

                .right {
                    display: flex;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: 0;
                    gap: 15px;
                    max-width: 50%;

                    .detail {
                        display: flex;
                        gap: 10px;

                        .info {

                            .title {
                                font-weight: 500;
                                font-size: 15px;
                            }

                            .subtitle {
                                font-size: 14px;
                            }

                            .subtitle-small {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .order-body {
                display: flex;
                margin-top: 20px;
                
                .product {
                    display: flex;
                    gap: 10px;

                    .product-image {
                        border-radius: 8px;
                        height: 80px !important;
                        width: 80px !important;
                    }

                    .text-information {
                        
                        .product-name {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            color: rgb(32, 34, 35);
                            cursor: pointer;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }

                        .note {
                            font-size: 12px;
                            line-height: 20px;
                            color:#202223;
                        }

                        .quantity-and-unit-price {
                            color: #6d7175;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                        }

                        .goods-price {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 700;
                            color: #ed1b24;
                        }
                    }
                }
            }

            .order-footer {
                display: flex;

                .footer-left-side {
                    .repacking-container {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 10px;

                        .icon {
                            font-size: 18px;
                        }

                        .text {
                            font-weight: 600;
                        }
                    }

                    .shipping-capital-input {
                        margin-bottom: 14px;

                        .form-control {
                            display: flex;
                            flex-direction: column;

                            .label {
                                font-size: 13px;
                                font-weight: 500;
                                margin-bottom: 3px;
                            }
                        }
                    }
                    
                    .admin-note {
                        display: flex;
                        gap: 10px;

                        &:hover {
                            text-decoration: underline;
                        }

                        .text {
                            font-size: 13px;
                            cursor: default;
                        }

                        .icon {
                            font-size: 17px;
                            margin-top: 2px;
                        }
                    }
                }
                
                .footer-right-side {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: 0;

                    .total-product {
                        display: flex;
                        white-space: nowrap;
                        gap: 10px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #202223;

                        .amount {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: #ed1b24;
                        }
                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .detail-btn {
                            display: flex;
                            width: fit-content;
                            margin-left: auto;
                            margin-right: 0;
                        }

                        .finish-btn {
                            background: #155724;
                        }
                    }
                }
            }

            .order-header {
                display: flex;
                width: 100%;

                .right {
                    display: flex;
                    width: fit-content;
                    height: fit-content;
                    margin-left: auto;
                    margin-right: 0;
                    align-items: center;
                    gap: 10px;

                    .last-track {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        
                        .text {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .process-time {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 24px;
                        border-radius: 8px;
                        padding: 1px 10px;
                    }

                    .status {
                        padding-block: .25rem;
                        padding-inline: .75rem;
                        border-radius: 9999px;
                        .text {
                            white-space: nowrap;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 600;
                        }
                    }
                }

                .left   {

                    .order-id {
                        font-size: 16px;
                        line-height: 24px;
                        color: #202223;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: 10px;
        
                        .copy-btn {
                            cursor: pointer;
        
                            &:hover {
                                color: var(--primary-color);
                            }
                        }
                    }

                    .created-date {
                        color: #6d7175;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}