.footer-copyright {
    background: #2125;
    z-index: 100;
    line-height: 23px;
    color: #fff;
    padding-block: 15px;
    text-align: center;
}

.footer {
    background: #333333;
    padding: 0;
    margin: 0;
    margin-bottom: -53px;

    .footer-bottom {
        display: flex;
        margin-inline: auto;
        margin-top: -30px;
        width: 1120px;
        max-width: calc(100vw - 20px);
        padding-bottom: 90px;

        .left {
            .follow-us {
                .title {
                    text-align:center;
                    color: #fff;
                }

                .medias {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    gap: 5px;

                    .media {
                        text-decoration: none !important;
                        color: #fff;;
                        .icon {
                            color: #fff;
                            font-size: 29px;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-top {
        color: #fff;
        background: #333333;
        padding: 0;
        margin: 0;
        padding-bottom: 30px;
        width: 1120px;
        max-width: calc(100vw - 20px);
        display: flex;
        flex-direction: column;
        margin-inline: auto;

        .ant-divider-horizontal {
            background: rgb(192, 180, 180);
            max-width: 85%;
            min-width: unset;
            display: flex;
            margin-inline: auto;
        }

        .footer-top-container {
            padding-block: 30px;

            .navigations {
                display: flex;
                width: 1400px;
                margin-inline: auto;
                max-width: 100%;
                flex-wrap: wrap;
                gap: 20px;
                
                .navigation {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: fit-content;
                    
                    .title {
                        font-size: 18px;
                        line-height: 22px;
                        color: #fff;
                        font-weight: 500;
                        white-space: nowrap;
                    }

                    .links {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        margin-top: 10px;
                        
                        .link {
                            text-decoration: none !important;
                            color: #b5b1b1 !important;
                            font-size: 14px;
                            line-height: 23px;
                            font-weight: 400;
                            white-space: nowrap;

                            &:hover {
                                color: var(--primary-color) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}