.child-container[data-name="register"] {
  .container {
    background: var(--primary-color);
    position: relative;
    padding-top: 100px;

    .inside-container {
      width: 1000px;
      display: flex;
      max-width: 90%;
      align-items: center;
      margin-inline: auto;
      gap: 20px;

      @media screen and (max-width: 860px) {
        width: fit-content;
      }

      .register-card {
        width: 450px;

        .title {
          text-align: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
        }

        .forms {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .login-text {
            color: rgb(109, 113, 117);
            line-height: 20px;
            text-align: center;

            a {
              font-weight: 500;
              color: var(--primary-color);

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .another-methods {
            display: flex;
            flex: 1;
            align-items: center;

            .method-btn {
              display: flex;
              flex: 1;

              .method {
                display: flex;
                align-items: center;
                width: fit-content;
                margin-inline: auto;
                gap: 5px;

                .icon {
                  width: 24px;
                }

                .text {
                  font-weight: 500;
                  line-height: 28px;
                }
              }
            }
          }
        }

      }

      .left {
        color: #fff;

        .ilustration {
          width: 500px;
          max-width: 100%;
        }

        .title {
          font-weight: 500;
          font-size: 20px;
          color: #fff;
          text-align: center;
        }

        .desc {
          text-align: center;
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }
}