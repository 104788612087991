.home-container.element {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    > .main-container {
        position: absolute;
        width: calc(100vw - 0px);
        height: calc(100vh - 60px);
        margin-top: 60px;
        overflow: auto;

        .child-container {
            height: calc(100vh - 60px);
            position: relative;
            overflow: auto;
            padding: 0;
        }
    }

    > .header {
        background: var(--primary-color);
        padding: 10px;
        display: flex;
        align-items: center;
        width: calc(100vw - 20px);
        transition: background 1000ms linear;

        .right {
            display: flex;
            align-items: center;
            gap: 20px;
            width: fit-content;
            margin-right: 20px;

            .enter-btn {
                background: var(--primary-color);
                color: #fff;;
            }

            .register-btn {
                background: #fff;
                color: var(--primary-color);
            }
        }
        
        .left {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 10px;

            .app {
                display: flex;
                align-items: center;
                color: #fff;
                gap: 10px;
                user-select: none;
                cursor: pointer;

                .logo {
                    height: 40px;
                    margin-left: 10px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    display: none;

                    .title,.subtitle {
                        font-weight: 600;
                        font-size: 14px;
                    }
    
                    .title {
                        font-size: 19px;
                    }

                    .subtitle {
                        margin-top: -6px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .profile-toggle-btn, .notification-toggle-btn {
        color: #fff;
        .icon {
            color: #fff;
            font-size: 30px;
        }
    }
}

.header-profile-detail {
    width: 150px;
    position: relative;

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .menus {
        display: flex;
        flex-direction: column;
        flex: 1;

        .menu-btn {
            display: flex;
            flex: 1;
            text-align: center;

            .ant-btn-icon {
                display: flex;
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

/* Notification */
.notification-dialog-content {
    width: 350px;
    max-width: 95%;
    position: relative;
    height: 500px;
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .empty-container {
        margin-top: 100px;
    }

    .notifications {
        display: flex;
        flex-direction: column;

        .ant-scroll-number-only-unit {
            display: none;
        }

        .notification-item {
            display: flex;
            flex: 1;
            width: 100%;
            flex-direction: column;
            height: fit-content;
            padding: 6px 10px;

            .title, .description {
                max-width: 100%;
                white-space: initial;
                text-align: left;
            }

            .title {
                font-weight: 600;
                font-size: 16px;
            }

            .description {
                font-size: 14px;
                font-weight: 400;
            }

            .date {
                display: flex;
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
                font-weight: 300;
                font-size: 13px;
            }

        }
    }
}
/* End of notification */

.notification-title {
    display: flex;

    .right-side {
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-right: 0;

        .link {
            color: var(--primary-color);
            // font-weight: 400;
            font-size: 12px;
            user-select: none;

            &:disabled {
                color: dimgray !important;
            }

            &:not(:disabled):hover {
                opacity: .8;
                color: var(--primary-color) !important;
            }
        }
    }
}