@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";


body {
    font-family: "Poppins", sans-serif;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --primary-color: #EB1C24;
    --dark-purple: #453d9e;
    --white: #ffffff;
    --semi-primary: #7e77ca;
    --semi: #a19cd8;
    --border-color: rgba(209, 213, 219, 1);
}

.ant-card {
    border-color: var(--border-color);
}


.ant-divider {
    border-block-start: 1px solid var(--border-color);
}

// Set the summary of table by default
.ant-table-thead tr th,
.ant-table-summary tr td {
    background: #F3F3F3 !important;
}

.ant-table-summary tr td {
    font-weight: 600;
}

.ant-table-cell {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}