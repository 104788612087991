.child-container[data-name="syarat-dan-ketentuan"] {
    .top-header {
        margin-inline: auto;
        padding-block: 70px;
        background: var(--primary-color);
        padding-bottom: 120px;
        border-bottom-left-radius: 30%;
        border-bottom-right-radius: 30%;

        .container {
            width: 1120px;
            margin-inline: auto;
            max-width: 100%;

            .title {
                text-align: center;
                color: #fff;
                font-weight: 600;
                font-size: 31px;
            }
            
            .desc {
                color: #fff;
                text-align: center;
                margin-top: 30px;
            }
        }
    }

    .card {
        background: #fff;
        width: 1120px;
        max-width: 100%;
        margin-inline: auto;
        margin-top: -60px;
        padding: 40px 25px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        section {
            .title {
                color: #1b2c42;
                font-size: 23px;
                font-weight: 700;
            }

            .l-text {
                color: #8292a6;
                font-size: 16px;
                line-height: 32px;
                white-space: pre-line;
            }
        }
    }
}