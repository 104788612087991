.main-app-container[data-name="kritik-dan-saran"] {
    .complaint-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .complaint-item {
            .message {
                font-weight: 500;
                font-size: 13px;
                white-space: pre-line;
            }

            .date {
                color: var(--primary-color);
                font-size: 11px;
            }
        }
    }

    .load-more-btn {
        display: flex;
        align-items: center;
        width:fit-content;
        margin-inline: auto;
    }
}