.main-app-container[data-name="profile"] {
    .form-controls {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        .form-control {
            .label {
                font-size: 14px;
                color: #202223;
                line-height: 20px;
            }

            .input {
                color: #202223;
                font-size: 14px;
                line-height: 20px;
                margin-top: 6px;
            }
        }
    }

    .save-btn {
        width: 100%;
    }
}