.main-app-container[data-name="order-detail"] {
    .loading-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: fit-content;
        height: fit-content;
        flex-direction: column;

        .spinner {
            margin-bottom: 20px;
        }

        .title {
            text-align: center;
            font-weight: 700;
            font-size: 16px;
        }
    }


    .error-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .image {
            width: 200px;
            max-width: 100%;
            display: flex;
            margin: auto;
        }

        .title {
            font-weight: 600;
            line-height: 24px;
            margin-top: 20px;
            font-size: 16px;
        }
    }

    .back-btn {
        cursor: pointer;
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 5px;

        .text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #202223;
        }
    }

    .detail-card {
        margin-top: 20px;

        .head {
            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #202223;
            }
        }

        .divider {
            margin-block: 15px;
        }
    }

    .detail-card.actions {
        .body {
            .row {
                display: flex;
                align-items: center;
                width: 100%;

                .right {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    gap: 20px;
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }
    }

    .detail-card.prices {
            .prices {
                display: flex;
                flex-direction: column;
                gap: 7px;

                .price {
                    display: flex;
                    align-items: center;

                    .left {
                        width: 100%;
                        font-size: 14px;
                        line-height: 20px;
                        color: #6d7175;
                    }

                    .right {
                        display: flex;
                        width: fit-content;
                        font-size: 14px;
                        line-height: 20px;
                        color: #202223;
                    }

                    .left,.right {
                        white-space: nowrap;
                    }
                }
            }
    }

    .detail-card.payment-method {
        background: #d4edda;
        border-color: #c3e6cb !important;   
        .body {
            .row {
                display: flex;
                align-items: center;
                width: 100%;

                .left {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    
                    .text {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #155724;
                        // color: #202223;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: 0;

                    .payment-method-btn {
                        // color: var(--primary-color);
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        background: #155724;
                    }
                }
            }
        }
    }

    .detail-card.product {
        .body {
            .order-footer {
                display: flex;
                width: 100%;
                align-items: center;

                .left, .right {
                    white-space: nowrap;
                }
                
                .left {
                    display: flex;
                    width: 100%;
                    font-size: 16px;
                    line-height: 24px;
                    color: #202223;
                }

                .right {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    color: #ed1b24;
                }
            }

            .row {
                display: flex;
                align-items: center;
                gap: 10px;
                
                .product-image {
                    width: 96px !important;
                    height: 96px !important;
                    border-radius: .25rem;
                    cursor: pointer;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    .name {
                        font-size: 14px;
                        line-height: 20px;
                        color: #6d7175;
                    }

                    .quantity {
                        font-size: 14px;
                        line-height: 20px;
                        color: #202223;
                    }

                    .price {
                        font-size: 16px;
                        line-height: 24px;
                        color: #ed1b24;
                    }
                }
            }
        }
    }

    .detail-card.address {
        .body {
            .name {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #202223;
            }
            
            .desc {
                font-size: 14px;
                line-height: 20px;
                color: #6d7175;
                white-space: pre-line;
            }


            .change-address-btn {
                margin-top: 15px;
                color: var(--primary-color);
                border-color: var(--primary-color);
            }
        }
    }

    .detail-card.tracks {
        .timeline {
            .date {
                font-size: 14px;
                line-height: 20px;
                color: #202223;
            }

            .track-detail {
                .title {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;
                    color: #202223;
                }

                .desc {
                    font-size: 14px;
                    line-height: 20px;
                    color: #202223;
                    margin-top: 5px;
                }
            }
        }
    }

    .detail-card.transaction {
        .body {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .row {
                display: flex;
                width: 100%;
                align-items: center;

                .left {
                    display: flex;
                    width: 100%;
                    font-size: 14px;
                    line-height: 20px;
                    color: #6d7175;
                }

                .right {
                    display: flex;
                    margin-left: auto;
                    margin-right: 0;
                    width: fit-content;
                    font-size: 14px;
                    line-height: 20px;
                    color: #202223;
                    align-items: center;
                    gap: 8px;

                    .icon {
                        font-size: 15px;
                        cursor: pointer;
                    }
                }

                .left,
                .right {
                    white-space: nowrap;
                }
            }
        }
    }
}