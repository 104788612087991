.delivered-proof-modal {
    .forms {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .form-control {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .title {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .render-file {
            margin-bottom: 20px;
        }
    }
}

.set-harga-new-order-modal {
    .add-btn {
        width: 100%;
        margin-top: 10px;
    }

    .prices {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;

        .price-item {
            padding-inline: -5px;
            padding-block: -10px !important;
            display: flex;
            width: 100%;
            gap: 10px;
            
            .form-control {
                display: flex;
                flex-direction: column;
                width: 100%;

                &:first-child {
                    width: 100px;
                }

                .title {
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .delete-btn {
                display: flex;
                margin-top: auto;
                margin-bottom: 0;
            }
        }
    }
}

.change-status-order-modal {
    .select {
        display: flex;
        width: 100%;
    }
}