.main-app-container[data-name="manage-user"] {

    .ant-tabs-nav::before {
        display: none;
    }

    .tab {
        display: flex;
        height: 47px;
        margin-top: -15px;
    }
    
    .search-field {
        margin-top: 10px;
    }

    .users {
        height: calc(100vh - 185px);
        margin-top: 10px;
        overflow-y: auto;
    }

    .user-item {
        display: flex;
        width: 100%;

        &:not(:first-child) {
            margin-top: 10px;
        }

        .ant-card-body {
            width: 100%;
            display: flex;
        }

        .profile-detail {
            display:flex;
            width: fit-content;
            flex-direction: column;

            .name {
                font-weight: 500;
                font-size: 14px;
            }

            .email,.phone,.gender,.birth-date {
                font-size: 12px;
                cursor: default;

                &:hover {
                    text-decoration: underline;
                }
            }

            .since {
                margin-top: 10px;
            }

            .since,.last-login {
                font-size: 13px;
                color: rgba(105, 105, 105, 0.466);
            }
        }

        .right {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            flex-direction: column;
            gap: 3px;

            .roles {
                text-transform: capitalize;
                display: flex;
                align-items: center;
                white-space: nowrap;
                gap: 8px;

                .current-role {
                    &:hover {
                        text-decoration: underline;
                        cursor: default;
                    }
                }
            }
        }
    }
}