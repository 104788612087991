.child-container[data-name="faq"] {
    .faq-head {
        background: var(--primary-color);
        padding-block: 60px;
        padding-inline: 10px;

        .title,.desc {
            color: #fff;
        }

        .title {
            font-weight: 500;
            text-align: center;
            font-size: 40px;
            line-height: 53px;
        }

        .desc {
            text-align: center; 
            font-size: 18px;
            line-height: 26px;
            margin-top: 25px;
        }

        .container {
            display: flex;
            flex-direction: column;
            width: 800px;
            max-width: 100%;
            margin-inline: auto;
        }
    }

    .faq-body {
        padding-inline: 50px;
        padding-block: 50px;
        .collapse {
            background: transparent;
        }
    }
}