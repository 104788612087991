.auth-login-modal {
    .ant-modal-footer {
        display: none;
    }

    .login-btn {
        width: 100%;
    }

    .forms {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .error-message {
            margin-top: -15px;
        }

        .register-text {
            text-align: end;

            a {
                color: var(--primary-color) !important;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .divider {
            color: rgb(109, 113, 117);
            font-weight: 400;
            line-height: 20px;
        }

        .another-methods {
            display: flex;
            width: 100%;
            align-items: center;

            .method-btn {
                display: flex;
                flex: 1;

                .inside {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    width: fit-content;
                    margin: auto;

                    .icon {
                        width: 24px;
                    }
                    
                    .text {
                        color: #202223;
                        font-size: 14px;
                        line-height: 28px;;
                    }
                }
            }
        }

        .form-control {
            width: 100%;
            font-size: 17px;
            line-height: 20px;
            color: rgb(17, 24, 39);

            .icon {
                color: rgb(17, 24, 39);
            }
        }
    }
}